import { useButton } from '@react-aria/button';
import { useHover } from '@react-aria/interactions';
import classNames from 'classnames';
import React from 'react';

import Spinner from '../spinner';
import { useButtonStyles } from './style';
import { ButtonBaseProps } from './types';

export interface ButtonProps extends ButtonBaseProps {
  children: React.ReactNode;
  onPress?: () => any;
  type?: 'button' | 'reset' | 'submit';
}

export const Button: React.FC<ButtonProps> = (props) => {
  let {
    children,
    onPress,
    disabled = false,
    type = 'button',
    appearance = 'secondary',
    isLoading = false,
    isIcon = false,
  } = props;

  let ref = React.useRef<HTMLButtonElement>(null);
  let { buttonProps, isPressed } = useButton(
    {
      onPress,
      type,
      children,
      isDisabled: disabled,
    },
    ref
  );
  let { hoverProps, isHovered } = useHover({});

  let isActive = isHovered || isPressed;

  const { className, style } = useButtonStyles({ ...props, isActive });

  return (
    <button {...hoverProps} {...buttonProps} className={className} style={style} ref={ref}>
      <div
        className={classNames('flex items-center justify-center', {
          'h-full w-full': !isIcon,
          'h-8 w-8': isIcon,
        })}
      >
        {isLoading ? <Spinner color={appearance === 'primary' ? 'white' : 'gray-700'} /> : children}
      </div>
    </button>
  );
};
