import '../polyfills';
import '../styles/style.scss';

import { ChakraProvider } from '@chakra-ui/react';
import { AppProps } from 'next/app';
import React from 'react';

import { ErrorBoundary } from '../components/organisms/error-boundary';
import { LanguageProvider } from '../contexts/language-context';
import sentry from '../utils/sentry';
import { cleanupServiceWorkers } from '../utils/service-workers';

export const { Sentry, captureException } = sentry();

cleanupServiceWorkers();

const App: React.FC<AppProps> = ({ Component, pageProps, router }) => {
  return (
    <ChakraProvider>
      <LanguageProvider initialLanguage={router.locale || router.defaultLocale || 'en'}>
        <ErrorBoundary>
          <Component {...pageProps} />
        </ErrorBoundary>
      </LanguageProvider>
    </ChakraProvider>
  );
};

export default App;
