import * as React from 'react';

import colors from '../colors';
import { darkenColor } from '../utils/color';
import createContext from './create-context';

type ProviderProps = {
  children: React.ReactNode;
  brandColor: string;
};

interface ColorTints {
  '100': string;
  '200': string;
  '300': string;
  '400': string;
  '500': string;
  '600': string;
  '700': string;
  '800': string;
  '900': string;
}

type ProviderValue = {
  brandColors: ColorTints;
};

let [useContext, ReactProvider, ReactConsumer] = createContext<ProviderValue>();

export function ThemeProvider(props: ProviderProps) {
  let { children, brandColor } = props;

  let providerValues = {
    brandColors: {
      '100': brandColor,
      '200': darkenColor(brandColor, 0.05),
      '300': darkenColor(brandColor, 0.1),
      '400': darkenColor(brandColor, 0.15),
      '500': darkenColor(brandColor, 0.2),
      '600': darkenColor(brandColor, 0.3),
      '700': darkenColor(brandColor, 0.4),
      '800': darkenColor(brandColor, 0.5),
      '900': darkenColor(brandColor, 0.6),
    },
    white: colors.white,
    black: colors.black,
  };

  return <ReactProvider value={providerValues}>{children}</ReactProvider>;
}

export const useTheme = useContext;
export const ThemeConsumer = ReactConsumer;
