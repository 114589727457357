import * as React from 'react';

import { useTheme } from '../../contexts/theme-context';
import { getDarkColor } from '../../utils/color';

export type Props = {
  children: React.ReactNode | React.ReactNodeArray;
  href: string;
};

export function Link(props: Props) {
  let { children, href } = props;
  let { brandColors } = useTheme();

  const textColor = getDarkColor(brandColors[200]);
  return (
    <a href={href} className="underline" style={{ color: textColor }}>
      {children}
    </a>
  );
}
