const colors = require('tailwindcss/colors');

module.exports = {
  ...colors,
  black: '#27272a',
  white: '#ffffff',
  brand: {
    50: '#d1c8b0',
    100: '#c7bca0',
    200: '#beb190',
    300: '#b5a681',
    400: '#ab9b71',
    500: '#A29061',
    600: '#928257',
    700: '#82734e',
    800: '#716544',
    900: '#61563a',
  },
};
