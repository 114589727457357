const STAGE = process.env.STAGE || 'development';
const getApiRoot = () => {
  switch (STAGE) {
    case 'production':
      return 'https://api.viresmo.com';
    case 'staging':
      return 'https://staging-api.viresmo.com';
    default:
      return 'http://localhost:4000';
  }
};

export const IS_PRODUCTION = STAGE === 'production';

export const SUPPORT_EMAIL = 'support@viresmo.com';
export const API_ROOT = getApiRoot();
export const GRAPHQL_ENDPOINT = API_ROOT + '/graphql';
export const SENTRY_DSN = 'https://fbad3973e0e54c47b50e95dec0a49ff9@o922682.ingest.sentry.io/5869741';
export const APPLICATION_VERSION = require('./package.json').version;
export const SHOPPING_CART_COOKIE_NAME = 'shopping-cart';
export const LAST_ORDER_COOKIE_NAME = 'last-order';
export const DEFAULT_BRAND_COLOR = '#A29061';

export const IOS_APP_ID = '1576886798';
export const ANDROID_APP_ID = 'com.viresmo.woyti';
