import { useTranslator } from '@translator-app/react';
import React from 'react';

import { Sentry, captureException } from '../../pages/_app';
import { MinimalServerError } from '../../types';
import { Button } from '../atoms/button';
import { Heading } from '../atoms/heading';
import { Link } from '../atoms/link';

interface Props {
  children: React.ReactNode;
}

interface State {
  error?: MinimalServerError | null;
}

export const ErrorFallback: React.FC<{ error?: MinimalServerError }> = (props) => {
  const { error } = props;
  let { translate } = useTranslator();

  return (
    <div className="max-w-screen-md p-8 mx-auto">
      <Heading>{translate('errorScreen.errorTitle')}</Heading>
      <div className="mt-4">{translate('errorScreen.errorDescription')}</div>
      <div className="my-4 flex justify-between">
        <Link href="/">{translate('errorScreen.seeAllShops')}</Link>
        <Button
          onPress={() => {
            window.location.reload();
          }}
        >
          {translate('errorScreen.refreshPage')}
        </Button>
      </div>

      {!!error && (
        <div className="uppercase text-gray-600 my-8">
          <div className="my-2 font-bold text-gray-800 text-sm">Technical information</div>
          <div className="my-1 text-sm">{error.message}</div>
          {!!error.eventId && <div className="my-1 text-sm">ID: {error.eventId}</div>}
        </div>
      )}
    </div>
  );
};

export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
    };
  }

  static getDerivedStateFromError(error: Error) {
    // React Error Boundary here allows us to set state flagging the error (and
    // later render a fallback UI).
    return {
      error: {
        message: error.message,
      },
    };
  }

  async componentDidCatch(error, errorInfo) {
    try {
      const eventId = captureException(error, { errorInfo });
      this.setState({
        error: {
          message: error.message,
          eventId,
        },
      });
      await Sentry.flush();
    } catch (err) {
      console.error(err);
    }
  }

  render() {
    if (this.state.error) {
      // Render error fallback
      return <ErrorFallback error={this.state.error} />;
    } else {
      // Render the normal page
      return this.props.children;
    }
  }
}
