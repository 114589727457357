import { useHover } from '@react-aria/interactions';
import Link, { LinkProps } from 'next/link';
import React from 'react';

import Spinner from '../spinner';
import { useButtonStyles } from './style';
import { ButtonBaseProps } from './types';

export interface LinkButtonProps extends LinkProps, ButtonBaseProps {
  children: React.ReactNode;
}

export const LinkButton: React.FC<LinkButtonProps> = (props) => {
  const { href, as, isLoading, children, appearance } = props;
  let { hoverProps, isHovered } = useHover({});

  let isActive = isHovered;

  const { className, style } = useButtonStyles({ ...props, isActive });

  return (
    <Link href={href} as={as} className={className} style={style} {...hoverProps}>
      {isLoading ? <Spinner color={appearance === 'primary' ? 'white' : 'gray-700'} /> : children}
    </Link>
  );
};
