import React from 'react';

export interface HeadingProps {
  children: React.ReactNode;
}

export const Heading: React.FC<HeadingProps> = (props) => {
  let { children } = props;

  return <h1 className="font-bold text-gray-700 text-3xl leading-8 mb-4">{children}</h1>;
};

export interface SubHeadingProps {
  children: React.ReactNode;
}

export const SubHeading: React.FC<SubHeadingProps> = (props) => {
  let { children } = props;

  return <h2 className="font-medium text-xl mb-2 text-gray-700">{children}</h2>;
};
