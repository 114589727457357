import { Integrations as ApmIntegrations } from '@sentry/apm';
import * as SentryIntegrations from '@sentry/integrations';
// NOTE: This require will be replaced with `@sentry/browser`
// client side thanks to the webpack config in next.config.js
import * as Sentry from '@sentry/node';
import cookie from 'cookie';

import { SENTRY_DSN } from '../constants';

type SentryLib = { Sentry: typeof Sentry; captureException: (error: any, ctx?: any) => null | string };

const initSentry = (release = process.env.SENTRY_RELEASE): SentryLib => {
  const sentryOptions = {
    dsn: SENTRY_DSN,
    release,
    maxBreadcrumbs: 50,
    attachStacktrace: true,
    tracesSampleRate: 0.25,
  };

  // When we're developing locally
  if (process.env.NODE_ENV !== 'production') {
    // Don't actually send the errors to Sentry
    // @ts-ignore
    sentryOptions.beforeSend = () => null;

    // Instead, dump the errors to the console
    // @ts-ignore
    sentryOptions.integrations = [
      new ApmIntegrations.Tracing(),
      new SentryIntegrations.Debug({
        // Trigger DevTools debugger instead of using console.log
        debugger: false,
      }),
    ];
  }

  Sentry.init(sentryOptions);

  return {
    Sentry,
    captureException: (error: any, ctx?: any) => {
      if (!error) {
        return null;
      }

      console.error(error);

      try {
        // @ts-ignore
        Sentry.configureScope((scope) => {
          if (error.message) {
            // De-duplication currently doesn't work correctly for SSR / browser errors
            // so we force deduplication by error message if it is present
            scope.setFingerprint([error.message]);
          }

          if (error.statusCode) {
            scope.setExtra('statusCode', error.statusCode);
          }

          if (ctx) {
            const { req, res, errorInfo, query, pathname } = ctx;

            if (res && res.statusCode) {
              scope.setExtra('statusCode', res.statusCode);
            }

            if (typeof window !== 'undefined') {
              scope.setTag('ssr', 'false');
              scope.setExtra('query', query);
              scope.setExtra('pathname', pathname);

              // On client-side we use js-cookie package to fetch it
              let cookieContent = cookie.parse(document.cookie);
              const sessionId = cookieContent.sid;
              if (sessionId) {
                scope.setUser({ id: sessionId });
              }
            } else {
              scope.setTag('ssr', 'true');
              scope.setExtra('url', req.url);
              scope.setExtra('method', req.method);
              scope.setExtra('headers', req.headers);
              scope.setExtra('params', req.params);
              scope.setExtra('query', req.query);

              // On server-side we take session cookie directly from request
              if (req.cookies && req.cookies.sid) {
                scope.setUser({ id: req.cookies.sid });
              }
            }

            if (errorInfo) {
              Object.keys(errorInfo).forEach((key) => scope.setExtra(key, errorInfo[key]));
            }
          }
        });

        return Sentry.captureException(error);
      } catch (err) {
        console.error(err);
        return null;
      }
    },
  };
};

export default initSentry;
