import color from 'color';

import colors from '../colors';

export function darkenColor(val: string, amount: number) {
  return color(val).darken(amount).rgb().string();
}

export function isLight(val: string) {
  return color(val).isLight();
}

/** Use this function to get the color to display on top of a white background */
export function getDarkColor(val: string) {
  if (isLight(val)) {
    return colors.black;
  } else {
    return val;
  }
}

/** Use this function to get the color to display together with the provided value (text <=> background) */
export function getContrastColor(val: string) {
  if (isLight(val)) {
    return colors.black;
  } else {
    return colors.white;
  }
}
