import classNames from 'classnames';

import colors from '../../../colors';
import { useTheme } from '../../../contexts/theme-context';
import { getContrastColor, getDarkColor } from '../../../utils/color';
import { ButtonBaseProps } from './types';

interface StylingProps extends ButtonBaseProps {
  isActive: boolean;
}

export function useButtonStyles(props: StylingProps) {
  let {
    disabled = false,
    appearance = 'secondary',
    isLoading = false,
    size = 'sm',
    isIcon = false,
    isActive = false,
  } = props;

  let { brandColors } = useTheme();
  let isDisabled = disabled || isLoading;

  let styling = classNames('block relative font-medium border rounded focus:outline-none whitespace-no-wrap', {
    'cursor-pointer focus:ring': !isDisabled,
    'text-white bg-gray-400 border-gray-400': disabled,
    'cursor-default': isDisabled,
    'text-white border-transparent': appearance === 'primary' && !disabled,
    'px-4 py-2 text-xl font-semibold w-full': size === 'lg' && !isIcon,
    'px-8 py-1': size === 'sm' && !isIcon,
    'text-gray-600 border-gray-600': appearance === 'subtle',
    'text-gray-800 border-gray-800': appearance === 'subtle' && isActive,
  });

  let style: React.CSSProperties = {};
  if (!disabled) {
    if (isActive) {
      if (appearance === 'primary') {
        style.backgroundColor = brandColors[600];
        style.color = getContrastColor(brandColors[600]);
      } else if (appearance === 'secondary') {
        style.backgroundColor = brandColors[600];
        style.borderColor = brandColors[600];
        style.color = getContrastColor(brandColors[600]);
      }
    } else {
      if (appearance === 'primary') {
        style.backgroundColor = brandColors[400];
        style.color = getContrastColor(brandColors[400]);
      } else if (appearance === 'secondary') {
        const textColor = getDarkColor(brandColors[400]);

        style.borderColor = textColor;
        style.color = textColor;
        style.backgroundColor = colors.white;
      }
    }
  }

  return {
    style,
    className: styling,
  };
}
