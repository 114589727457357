import { TranslatorProvider } from '@translator-app/react';
import * as React from 'react';

import de from '../i18n/de.json';
import en from '../i18n/en.json';
import fr from '../i18n/fr.json';
import nl from '../i18n/nl.json';

interface ProviderProps {
  children: React.ReactNode;
  initialLanguage: string;
}

interface ProviderValue {
  language: string;
  setLanguage: (newLang: string) => void;
}

const languageContext = React.createContext<ProviderValue>({ language: 'en', setLanguage: () => {} });

export function LanguageProvider(props: ProviderProps) {
  let { children, initialLanguage } = props;
  let [language, setLanguage] = React.useState(initialLanguage);

  return (
    <languageContext.Provider value={{ language, setLanguage }}>
      <TranslatorProvider language={language} fallbackLanguage="en" library={{ nl, en, fr, de }}>
        {children}
      </TranslatorProvider>
    </languageContext.Provider>
  );
}

export const useLanguageContext = () => {
  return React.useContext<ProviderValue>(languageContext);
};
