export function cleanupServiceWorkers() {
  try {
    if (typeof window !== 'undefined') {
      if (window.navigator && navigator.serviceWorker) {
        navigator.serviceWorker
          .getRegistrations()
          .then((registrations) => {
            for (let registration of registrations) {
              registration.unregister();
            }
          })
          .catch(console.error);
      }
    }
  } catch (err) {
    console.error(err);
  }
}
